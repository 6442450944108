// gatsby-browser.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './src/theme';
import './src/styles/main.css'; // Import the global CSS file
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@aws-amplify/ui-react/styles.css';


import { pdfjs } from 'react-pdf';
import { Amplify } from 'aws-amplify';
import awsConfig from './src/aws-exports';
Amplify.configure(awsConfig);



pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';


export const wrapRootElement = ({ element }) => {
  return (
      <ThemeProvider theme={theme}>
        {element}
      </ThemeProvider>
  );
};
