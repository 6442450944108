exports.components = {
  "component---src-pages-confirm-user-js": () => import("./../../../src/pages/confirm-user.js" /* webpackChunkName: "component---src-pages-confirm-user-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-doctrees-js": () => import("./../../../src/pages/doctrees.js" /* webpackChunkName: "component---src-pages-doctrees-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integration-ticket-js": () => import("./../../../src/pages/integration-ticket.js" /* webpackChunkName: "component---src-pages-integration-ticket-js" */),
  "component---src-pages-integration-ticket-submitted-js": () => import("./../../../src/pages/integration-ticket-submitted.js" /* webpackChunkName: "component---src-pages-integration-ticket-submitted-js" */),
  "component---src-pages-invoice-reconciliation-js": () => import("./../../../src/pages/invoice_reconciliation.js" /* webpackChunkName: "component---src-pages-invoice-reconciliation-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-ocr-js": () => import("./../../../src/pages/ocr.js" /* webpackChunkName: "component---src-pages-ocr-js" */),
  "component---src-pages-organization-js": () => import("./../../../src/pages/organization.js" /* webpackChunkName: "component---src-pages-organization-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment-success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-resource-js": () => import("./../../../src/pages/resource.js" /* webpackChunkName: "component---src-pages-resource-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */)
}

