// src/aws-exports.js
const awsConfig = {

    Auth: {

        Cognito: {
            userPoolId: 'us-west-2_m0MLVPD5B',
            userPoolClientId: 'fet6kscn52s3ppjcl1ulq9ga5',
            region: 'us-west-2',

        }

    }

};

export default awsConfig;
